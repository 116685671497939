import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-control-box',
  templateUrl: './control-box.component.html',
  styleUrl: './control-box.component.scss'
})
export class ControlBoxComponent {
  @Input() selectedPoints!: number;
  @Input() users!: any[];
  @Input() revealCards: boolean = false;
  @Output() revealEvent = new EventEmitter();
  currentUserId!: any;
  constructor() {
    this.currentUserId = sessionStorage.getItem('userId');
  }
  revealScore() {
    this.revealEvent.emit();
  }
}

<div style="    display: flex;
justify-content: center;">
<div>
    <div class="control-box text-center mt-4">
        <div class="control-button">
            <button *ngIf="currentUserId" class="btn btn-primary btn-control" (click)="revealScore()">
                Reveal Cards
            </button>
            <p *ngIf="!currentUserId" class="waiting-text">
                Waiting for Moderator to Reveal the cards
            </p>
        </div>
    </div>
    <div class="user-cards">
        <div class="user-card" *ngFor="let user of users;let i = index">
            <div class="card-layout" [class.selected]="user?.score > 0 && !revealCards">
               <div *ngIf="revealCards">
                {{user?.score}}
               </div> 
            </div>
            <div class="card-title">{{user.name}} {{user.score}}</div>
        </div>
        <!-- <div class="user-card" *ngFor="let user of userCards;let i = index">
            <div class="card-layout" [class.selected]="currentUserId == user.userId && selectedPoints">
               <div *ngIf="currentUserId == user.userId && selectedPoints != 0">
                {{selectedPoints}}
               </div> 
            </div>
            <div class="card-title">{{user.name}}</div>
        </div> -->
    </div>
</div>

    
</div>
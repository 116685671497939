<app-header></app-header>
<div class="container-fluid">
  <div class="row">
    <div>
      <app-control-box [revealCards]="revealCards" (revealEvent)="onRevealEvent()" [users]="connectedClients" [selectedPoints]="selectedPoints"/>
    </div>
    <div class="bottom-container" *ngIf="!revealCards">
      <app-story-point-selection (selectedStoryPoint)="onStoryPointChange($event)" />
    </div>
    {{code}} - {{revealCards}}
    <div class="metric" *ngIf="revealCards">
      <!-- <app-metric-panel [metric]="metricData"></app-metric-panel> -->
    </div>
  </div>
</div>

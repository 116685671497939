import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-header', 
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @ViewChild('textInput', { static: false }) textInput!: ElementRef;
  @Input() showInvite!: boolean;
  @Output() onRoomClicked = new EventEmitter();
  showMenu!: boolean;
  inviteUrl: string ='asdfdsafsdfdssdafasf';
  showInviteModal!: boolean;
  value = 'hello rahuv'
  isLoggedIn = false;
  constructor(private userSer: UserService) {
    this.isLoggedIn = this.userSer.isLoggedIn();
  }
  showOptions() {
    this.showMenu = true;
  }

  generateInvite() {
    // this.inviteUrl = `http://localhost:4200/join/${sessionStorage.getItem('code')}`;
  }
  createRoom() {
    let title = 'mettingg'
    this.onRoomClicked.emit(title);
  }


  logout() {
    this.userSer.logout();
  }

}

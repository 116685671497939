import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from '../../environment/environment';
 
@Injectable()
export class SocketsService {
  private socket!: Socket;
 
  constructor() {
    const token = sessionStorage.getItem('token');
    this.socket = io(environment.socketUrl, {
      query: {
        code: sessionStorage.getItem('code'),
        name: sessionStorage.getItem('username')
      },
      auth: {
        token: token
      }
    });
  }
 
  sendMessage(message: string): void {
    this.socket.emit('message', message);
  }

  sendRevealEvent(): void {
    this.socket.emit('reveal', true);
  }
 
  receiveMessages(): Observable<string> {
    return new Observable<string>(observer => {
      this.socket.on('message', (data: string) => {
        observer.next(data);
      });
    });
  }
 
  receiveConnectedClients(): Observable<string[]> {
    return new Observable<string[]>(observer => {
      this.socket.on('connectedClients', (data: string[]) => {
        observer.next(data);
      });
    });
  }

  revealCardStatus(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.socket.on('revealCardFromSocket', (data: boolean) => {
        observer.next(data);
      });
    });
  
  }

  receiveConnectedParticipants() {
    return new Observable<string>(observer => {
      this.socket.on('newParticipant', (data: string) => {
        observer.next(data);
      });
    });
  }
}
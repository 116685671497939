import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environment/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentRoom = new BehaviorSubject(null);
  constructor(private http: HttpClient, private router: Router) { }

  getCurrentRoom() {
    return this.currentRoom.asObservable();
  }

  setCurrentRoom(room: any) {
    this.currentRoom.next(room);
  }

  login(loginObj: any) {
    return this.http.post(environment.apiUrl+'/users/login', loginObj);
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

  decodeTokenAndStoreInSessionStorage(token: string) {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('userId', decodedToken.userId);
    sessionStorage.setItem('email', decodedToken.email);
    sessionStorage.setItem('username', decodedToken.username);  
  }

  isLoggedIn() {
    return !!sessionStorage.getItem('token');
  }

  validateToken() {
    return this.http.get(environment.apiUrl+'/users/validateToken');
  }

  checkForActiveRoom() {
    return this.http.get(environment.apiUrl+'/users/activeRoom');
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocketsService } from './services/socket.service';
import { ControlBoxComponent } from './components/control-box/control-box.component';
import { PlanningComponent } from './components/planning/planning.component';
import { StoryPointSelectionComponent } from './components/story-point-selection/story-point-selection.component';
import { PlanningPokerRoutingModule } from './planning-poker-routing.module';
import { SharedModule } from '../shared/shared.module';
// import { MetricPanelComponent } from './components/metric-panel/metric-panel.component';



@NgModule({
  declarations: [
    ControlBoxComponent,
    PlanningComponent,
    // MetricPanelComponent,
    StoryPointSelectionComponent
  ],
  imports: [
    CommonModule,
    PlanningPokerRoutingModule,
    SharedModule
  ],
  providers: [
    SocketsService
  ]
})
export class PlanningPokerModule { }

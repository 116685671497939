import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { io, Socket } from "socket.io-client";
import { environment } from "../environment/environment";

@Injectable()

export class PlanningPokerService {
    private socket!: Socket;
    constructor(private http: HttpClient) {

    }

    sendMessage(message: any) {
        this.socket.emit('message', message);
    }

    receiveIdOnConnection() {
        return new Observable((observer) => {
            this.socket.on('connection', (data: string) => {
                observer.next(data);
            })
        })
    }

    receiveMessage() {
        return new Observable((observer) => {
            this.socket.on('message', (data: string) => {
                observer.next(data);
            })
        })
    }

    receiveConnectedClients() {
        return new Observable((observer) => {
            this.socket.on('connectedClients', (data: string) => {
                observer.next(data);
            })
        })
    }

    createRoom(title: string) {
        let req = {
            title: title
        }
        return this.http.post(environment.apiUrl+'/rooms', req);
    }

    endRoom(roomId: number) {
        return this.http.put(environment.apiUrl+'/users/endRoom/'+roomId, {});
    }

}
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-story-point-selection',
  templateUrl: './story-point-selection.component.html',
  styleUrl: './story-point-selection.component.scss'
})
export class StoryPointSelectionComponent {
  @Output() selectedStoryPoint = new EventEmitter();
  storyCards = [1,2,3,5,8,13,21];

  selectedCardIndex!: number;

  onCardSelected(i: number) {
    this.selectedCardIndex = i;
    this.selectedStoryPoint.emit(this.storyCards[this.selectedCardIndex]);
  }
}

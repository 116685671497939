<div class="title text-center mb-3">
    Choose Your Card 👇
</div>
<div class="story-cards">
    <div class="story-card-container" (click)="onCardSelected(i)" *ngFor="let story of storyCards;let i = index">
        <div class="story-card" [class.selected]="i == selectedCardIndex">
            <div class="card-layout" >
                <div class="card-title">{{story}}</div>
            </div>
        </div>
    </div>
</div>
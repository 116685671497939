import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { PlanningPokerService } from '../../../services/planning-poker.service';
import { HttpHeaders } from '@angular/common/http';
import { SocketsService } from '../../services/socket.service';
@Component({
  selector: 'app-planning',
 
  templateUrl: './planning.component.html',
  styleUrl: './planning.component.scss'
})
export class PlanningComponent {
  title = 'planning-poker';
  selectedPoints!: number;
  message: any;
  connectedClients: string[] = [];
  code = sessionStorage.getItem('code');
  revealCards = false;
  metricData = {};
  private messageSubscription!: Subscription;
  private connectedClientsSubscription!: Subscription;
  private revealCardValue!: Subscription;
  private connectedParticipantsSubscription!: Subscription;

  private connectionSubscription!: Subscription;
  showInvite!: boolean;

  constructor(private planningSer: PlanningPokerService, private socketService: SocketsService) {
    this.messageSubscription = this.socketService.receiveMessages().subscribe(data => {
      console.log('message',data)
      this.message = data;
    });
    this.connectedClientsSubscription = this.socketService.receiveConnectedClients().subscribe(data => {
      this.connectedClients = data;
      console.log('connectedClients',data)
    });

    this.connectedParticipantsSubscription = this.socketService.receiveConnectedParticipants().subscribe(data => {
      console.log('connectedParticipants',data)
    });

    this.revealCardValue = this.socketService.revealCardStatus().subscribe(data => {
      this.revealCards = true;
      this.metricData = data;
      console.log('revealCardValue',data)
    })
  
    // this.connectionSubscription = this.planningSer.receiveIdOnConnection().subscribe((data: any) => {
    //   console.log('On Connection',data);
    //   sessionStorage.setItem('userId', data.userId);
    // })
    // this.messageSubscription = this.planningSer.receiveMessage().subscribe((data: any) => {
    //   console.log('On Connection',data);
    //   this.message = data;
    // })
    // this.connectedClientsSubscription = this.planningSer.receiveConnectedClients().subscribe((data: any) => {
    //   console.log('Total',data);
    //   this.connectedClients = data;
    // })
  }

  onRevealEvent() {
    this.socketService.sendRevealEvent()
  }

  onStoryPointChange(index: number) {
    this.selectedPoints = index;
    let obj = {
      userId: sessionStorage.getItem('userId'),
      storyPoint: index
    }
    this.socketService.sendMessage(this.selectedPoints.toString());
    // this.planningSer.sendMessage(obj);
    // console.log('selected',this.selectedPoints)
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    this.connectedClientsSubscription.unsubscribe();
  }

  createRoom(title: string) {
    const headers = new HttpHeaders().set('Authorization',  `Bearer ${sessionStorage.getItem('token')}`);
    this.planningSer.createRoom(title).subscribe((res: any) => {
      if(res) {
        this.showInvite = true;
        sessionStorage.setItem('code', res.code);
      //   {
      //     "title": "mettingg",
      //     "moderatorID": 2,
      //     "gameCount": 1,
      //     "code": "dY6PedWk",
      //     "isActive": true,
      //     "id": 3,
      //     "created_at": "2024-02-25T15:01:38.000Z",
      //     "updated_at": "2024-02-25T15:01:38.000Z"
      // }
      }
    });
  }
 
}

<nav class="navbar navbar-expand-lg navbar-light bg-light">
 <div class="d-flex">
  <a class="navbar-brand" href="#">
    <img src="assets/logo.svg" alt="">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link" href="#">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">About</a>
      </li>
      <li class="nav-item" *ngIf="showInvite">
        <button class="btn btn-primary btn-sm" (click)="generateInvite()" data-bs-toggle="modal" data-bs-target="#exampleModal">Invite Team</button>
      </li>
      <li *ngIf="!showInvite">
        <!-- <button (click)="createRoom()" class="btn btn-primary btn-sm">Create Room</button> -->
      </li>
      

    </ul>
   
  </div>
 </div>
  <div *ngIf="isLoggedIn">
    <button class="btn btn-danger btn-sm" (click)="logout()">Logout</button>
  </div>
</nav>



<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Share with your team to join</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="form-group d-flex">
    
        </div>
      </div>
    </div>
  </div>
</div>